/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Primečiau, kad nelaikau juodraščių. Neturiu reikalo kažką pradėt ir\nnebaigus mest. Tuo pačiu neturiu ir milijoninio gerbėjų ir mylėtojų\nanšlago. Ir tada, kai visi turi savo penkioliką sekundžių šlovės, aš esu\nabsoliučiai garsus. Kai mane norisi išgirsti, galima tiesiog susivesti\nadresą ir iškart pasiekti mano garsus. Žinomas – gerokai mažiau, žinoma."), "\n", React.createElement(_components.p, null, "Aš užaugau internetuose. Aš dirbu internetuose ir kuriu internetams.\nLaisvalaikiu irgi kuriu internetams. Tiesiog va toks vat internetinis\nesu. Eterinis. Nes internetuose eterio apsčiai ir imtis jojo gali\nkiekvienas eterinis kūnas. Tik diskutuoju, kažkaip taip gaunasi,\npraktiškai visiškai ne internetuose. Internetuose tiesiog dėstau tiesas."), "\n", React.createElement(_components.p, null, "Tiesas tai čia iš tos pusės, kur tai yra dalykai, kuriais aš tikiu. Mano\ntikėjimas gali pasikeisti, bet tuo metu, kai tai yra rašoma, mano tiesa\nyra tiesų tiesiausia (čia imta iš\n", React.createElement(_components.a, {
    href: "http://besarmatos.kartais.lt/"
  }, "be sarmatos"), ", kontekstui.)"), "\n", React.createElement(_components.p, null, "Toks jau tas mano tikėjimas, kad viską, ką turi pagaminęs, reikia pist\ntiesiai internetams. Kad būtų tas jau visaip linksniuojamas\n", React.createElement(_components.em, null, "release'as"), ". Suprask, kad tai tiesiog būtų prieinama bet kuriuo metu\nir iš bet kur. Kaip ir šitas kartais.lt. Yra tekstas – tekstas\nprieinamas. Yra app'as – app'as prieinamas."), "\n", React.createElement(_components.p, null, "Suprask, reikia dirbti link tos pačios pačios minimaliausios, bet esmę\nduodančios versijos, kuri veikia nepriekaištingai. Gal jai kažko\ntrūksta, kažkas būtų maloniau paspausti kitaip, bet ji veikia ir\nrezultatą duoda akimirksniu, pavyzdžiui. Nebūtina šitą versiją visaip\nafišuoti ir reklaminius plotus pirkti, bet viešinti, man atrodo, reikia."), "\n", React.createElement(_components.p, null, "Kad truputį daugiau atsakomybės būtų, tikriausiai. Nes labai lengva yra\npasiskelbti, kad mes už metų va startuosim. Ir visus metus galima šūdą\nmalt, iki kol ateis tų metų pabaiga ir reikės startuoti. Ir kai jau\nnorėsis startuoti, pasimatys, kad čia tie programišiai bybių\npriprogramavo – nežinau kur jie žiūrėjo, bet tikrai ne ten, kur aš. Kas\nkaip ir esmė, gaunasi: abu ten pat turėjo žiūrėt. O nežiūrėjo tik dėl\nto, kad ", React.createElement(_components.em, null, "deadline'as"), " yra. Ateis ", React.createElement(_components.em, null, "deadline'as"), ", tada ir pažiūrėsim.\nArba ateis ", React.createElement(_components.em, null, "deadline'as"), " ir praeis. Čia jau nuo perspektyvos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
